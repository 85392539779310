import { BREAKPOINTS } from 'javascripts/constants/index';
import NavigationSearch from './navigation-search';
import NavigationDesktop from './navigation-desktop';
import NavigationMobile from './navigation-mobile';
import NavigationToggle from './navigation-toggle';

const mql = window.matchMedia(`(min-width: ${BREAKPOINTS.xl})`);
const $navigationToggle = document.getElementById('navigation-toggle-button');
const $navigation = document.getElementById('navigation');

// set ios Class
const isIOS = /iPad|iPhone|iPod/.test(navigator.platform)
  || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
if (isIOS === true) {
  document.body.classList.add('ios');
}


if ($navigationToggle) {
  const navigationToggle = new NavigationToggle($navigationToggle);

  // Listener
  const onMediaQueryChange = () => {
    navigationToggle.init();
  };

  // Listen on media query changes
  mql.addEventListener('change', onMediaQueryChange);

  // First run
  onMediaQueryChange(mql);
}

if ($navigation) {
  $navigation.classList.add('js-navigation-initialized');
  const navigationMobile = new NavigationMobile($navigation);
  const navigationDesktop = new NavigationDesktop($navigation);

  // Listener
  const onMediaQueryChange = (mq) => {
    const isDesktop = mq.matches;
    if (isDesktop) {
      navigationMobile.deInit();
      navigationDesktop.init();
    } else {
      navigationMobile.init();
      navigationDesktop.deInit();
    }
  };

  // Listen on media query changes
  mql.addListener(onMediaQueryChange);

  // First run
  onMediaQueryChange(mql);
}

const $navigationSearch = document.getElementById('navigationSearchToggle');
if ($navigationSearch) {
  // eslint-disable-next-line no-unused-vars
  const navigationSearch = new NavigationSearch($navigationSearch);
}
