import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { createFocusTrap } from 'focus-trap';
import Slider from 'javascripts/globals/slider';
import { videoOverlay } from 'components/atoms/media/video/video';

export default class Dialog {
  constructor($el, classes = { }) {
    this.$trigger = $el;
    this.$content = this.$trigger.getAttribute('aria-controls');

    this.classes = {
      dialogTemplate: 'dialog-template',
      dialog: 'dialog',
      content: 'dialog__content',
      closeButton: 'dialog__button--close',
      slider: 'dialog-content__slider',
      sliderFrame: 'dialog-content__slider-items',
      sliderItem: 'dialog-content__slider-item',
      ...classes,
    };

    this.initDialog();
  }

  initDialog() {
    // Get outer dialog template
    const $template = document.querySelector(`.${this.classes.dialogTemplate}`);

    this.$dialogElement = $template && $template.content
      ? document.importNode($template.content, true).querySelector(`.${this.classes.dialog}`)
      : document.querySelector(`.${this.classes.dialog}`);

    // Close button
    this.$dialogElement.querySelector(`.${this.classes.closeButton}`)
      .addEventListener('click', () => this.closeDialog());

    // Get dialog content container
    this.$dialogContent = this.$dialogElement.querySelector(`.${this.classes.content}`);

    // Define focus trap
    this.focusTrap = createFocusTrap(this.$dialogElement, {
      escapeDeactivates: false,
      clickOutsideDeactivates: true,
    });

    // Trigger event
    this.$trigger.addEventListener('click', () => { this.openDialog(this.$content); });

    // Close event on escape
    this.closeOnEscape = (e) => {
      if (e.key === 'Escape') {
        this.closeDialog();
      }
    };
  }

  loadTarget(targetId = false) {
    // Find dialog content container
    const $targetTemplate = targetId
      ? document.querySelector(`#${targetId}`)
      : console.warn('Missing target content template'); // eslint-disable-line
    const $targetContent = $targetTemplate
      ? document.importNode($targetTemplate, true)
      : false;

    // Insert target content into dialog
    if ($targetContent) {
      this.$dialogContent.innerHTML = '';
      $targetContent.removeAttribute('hidden');
      this.$dialogContent.appendChild($targetContent);
    }

    // Initialize slider if markup exists
    const isSlider = this.$dialogContent.querySelector(`.${this.classes.slider}`);

    if (isSlider) {
      this.slider = new Slider(this.$dialogContent, {
        id: `${targetId}-slider`,
        sliderFrame: this.classes.sliderFrame,
        sliderItem: this.classes.sliderItem,
      });
    }
  }

  openDialog(targetId) {
    this.loadTarget(targetId);
    document.body.appendChild(this.$dialogElement);
    document.addEventListener('keyup', this.closeOnEscape);
    disableBodyScroll(this.$dialogElement);
    this.focusTrap.activate();

    // Jump to defined slide
    if (this.$trigger.dataset.slide) {
      location.hash = this.$trigger.dataset.slide; // eslint-disable-line
    }

    // trigger Datenschutz Overlay wenn Video in einer Lightbox geöffnet wird
    videoOverlay();
  }

  closeDialog() {
    document.body.removeChild(this.$dialogElement);
    document.removeEventListener('keyup', this.closeOnEscape);
    enableBodyScroll(this.$dialogElement);
    this.focusTrap.deactivate();
    this.$dialogContent.innerHTML = '';

    // Clear hash
    if (this.$trigger.dataset.slide) {
      location.hash = ''; // eslint-disable-line
    }
  }
}

// Initialize all dialogs and triggers
document.querySelectorAll('.js-dialog-trigger').forEach(
  $dialogTrigger => new Dialog($dialogTrigger),
);
