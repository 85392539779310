import { createApp } from 'vue';
import TeaserJobListApi from './vue/teaser-job-list.vue';

// get all vue teaser-list instances
const listInstanceContainers = document.querySelectorAll('.teaser-job-list-api-wrapper');

// iterate through every container and create vue instances
listInstanceContainers.forEach((container) => {
  const cityElementIdData = container.dataset.cityElementId;
  const activityElementIdData = container.dataset.activityElementId;
  const maxJobsData = container.dataset.maxJobs;
  const apiUrlData = container.dataset.apiUrl;

  //  create vue app and pass initial props
  const app = createApp(TeaserJobListApi, {
    cityElementId: cityElementIdData,
    activityElementId: activityElementIdData,
    maxJobs: maxJobsData,
    apiUrl: apiUrlData,
  });

  // mount containers
  app.mount(container.querySelector('.teaser-job-list-api-app-root'));
});
